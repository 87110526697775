<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/img/header.jpg')"
      />
      <div class="content-center">
        <div class="container">
          <TypingTitle :titles="['Corporate Virtues.']" />
        </div>
      </div>
    </div>
    <div
      data-background-color="seus-green"
      class="section-virtues section-team text-center"
    >
      <div class="container ">
        <div class="row">
          <div class="col-md-6">
            <card>
              <div class="icon">
                <i class="fa-5x">
                  <font-awesome-icon icon="fa-solid fa-eye" />
                </i>
              </div>
              <h4 class="title2">
                Vision
              </h4>
              <p class="description">
                Our vision is to be the premier
                strategic partner in the field of
                environment and sustainability
                consulting.
                <br>We strive to achieve this by providing
                the highest quality of service to our
                clients and maintaining high levels of
                professional ethics and admirable
                core values.
              </p>
            </card>
          </div>


          <div class="col-md-6">
            <card>
              <div class="icon">
                <i class="fa-5x">
                  <font-awesome-icon icon="fa-solid fa-bullseye" />
                </i>
              </div>
              <h4 class="title2">
                MISSION
              </h4>
              <p class="description">
                We are determined to be the
                benchmark of quality within our
                industry by being the most innovative,
                diversified environmental consulting
                firm in Zambia and sub-Sahara Africa.
                <br>
                We have become a client centered and
                committed to technical excellence
                with significant resources devoted to
                building array of new
                products and services.
              </p>
            </card>
          </div>


          <div class="col-md-6">
            <card>
              <div class="icon">
                <i class="fa-5x">
                  <font-awesome-icon icon="fa-brands fa-leanpub" />
                </i>
              </div>
              <h4 class="title2">
                Philosophy
              </h4>
              <p class="description">
                To ensure Sustainable development as
                economic and social development for all
                is being sought while protecting the
                natural environment on which all life
                depends.
                <br>This is attained applying environmental
                laws with integrity and fairness in the
                process of conducting enviro-legal
                assessments and due diligence audits
                while identifying existing and future
                environmental risks and formulating
                equitable and appropriate mitigation
                measures.
              </p>
            </card>
          </div>


          <div class="col-md-6">
            <card>
              <div class="icon">
                <i class="fa-5x">
                  <font-awesome-icon icon="fa-regular fa-handshake" />
                </i>
              </div>
              <h4 class="title2">
                Commitment
              </h4>
              <p class="description">
                Commitment
                To empower our clients’ manpower
                complement to develop the professional
                capacity to prevent and minimize their
                organizations’ environmental risks and
                liabilities, resulting out of potential legal
                non-compliance or undue physical harm
                to the environment or social well-being
                of communities.
                <br>This is achieved by providing top quality
                and timely deliverables to clients by
                qualified and experienced professionals
                who commit to the Code of Professional
                Conduct and Ethics
              </p>
            </card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
    import {Card} from '@/components';
    import TypingTitle from '@/components/TypingTitle';

    export default {
        name: 'Virtues',
        bodyClass: 'virtues-page',
        components: {
            Card,
          TypingTitle

        },
        data() {
            return {};
        }
    };
</script>
<style></style>
